<template>
  <footer class="footer">
    <div id="footerContainer">
      <div id='footerLinks'>
        <a href="/">Home</a><br />
        <a href='/features'>Features</a><br />
        <!-- <a href='/demo'>Demo App</a><br /> -->
        <a href="mailto:contact@bookedsolid.co.uk">Email</a><br />
        <a href='/contact'>Contact</a><br />
        <a href='/terms'>Terms of Service</a><br />
        <a href='/privacy'>Privacy Policy</a><br />
        <a href="#" class="termly-display-preferences">Consent Preferences</a><br />
      </div>

      <img src="@/assets/name.png" alt="BookedSolid Logo" />

      <div id='footerBottom'>
        <p>&copy; 2023-24 BookedSolid Ltd. All rights reserved.</p>
        <a href="mailto:contact@bookedsolid.co.uk">contact@bookedsolid.co.uk</a><br /><br />

        <div id='socials'>
          <a href='https://www.linkedin.com/company/bookedsolid-co-uk/'><i className="fab fa-linkedin"></i></a>
          <a
            href="https://instagram.com/bookedsolid.co.uk"><i
              className="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
};
</script>

<style lang="scss" scoped>
.footer {
  display: block;
  position: absolute;
  background: linear-gradient(to top, #2596be60, white);
  width: 100vw;
  left: 0;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #333;
  overflow: hidden;

  #footerContainer {
    max-width: 1200px;
    margin: 0 auto;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  #footerLinks {
    padding: 30px;
    text-align: left;

    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      font-size: 16px;
      margin: 8px 0;
      cursor: pointer;

      &:hover {
        color: #ccc;
      }
    }
  }

  img {
    width: 200px;
    margin: 20px;
  }

  #footerBottom {
    padding: 20px;
    border-top: solid 2px inherit;
  }

  #socials {
    margin-top: 30px;

    a {
      padding: 20px;
    }
  }
}
</style>